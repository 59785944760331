import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Outrolocation from "../components/strapi/structure/OutroLocation";
import OutroPiggy from "../components/strapi/structure/Outropiggy";
import OneCol from "../components/strapi/structure/OneCol";
import TwoCol from "../components/strapi/structure/TwoCol";
import Beach from "../components/strapi/boutique/beach";
import Products from "../components/strapi/boutique/products";

export default function Boutique({ data }) {
  return (
    <Layout>
      <SEO title="Boutique" />

      {data.allStrapiBoutique.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.bq_mh.mh_title}
            mhsubtitle={node.bq_mh.mh_subtitle}
            mhbackground={node.bq_mh.mh_bg.publicURL}
          />

          <OneCol
            title={node.bq_onecol.onecol_title}
            description={node.bq_onecol.onecol_description}
          />

          <Beach />

          <TwoCol
            title={node.bq_relax.txtimg_title}
            description={node.bq_relax.txtimg_description}
            blueTitle={node.bq_relax.txtimg_bluetitle}
            blueLink={node.bq_relax.txtimg_bluelink}
            bgColor={node.bq_relax.txtimg_bgcolor}
            order={node.bq_relax.txtimg_order}
            rowimg={node.bq_relax.txtimg_img.publicURL}
          />

          <TwoCol
            title={node.bq_coffee.txtimg_title}
            description={node.bq_coffee.txtimg_description}
            blueTitle={node.bq_coffee.txtimg_bluetitle}
            blueLink={node.bq_coffee.txtimg_bluelink}
            bgColor={node.bq_coffee.txtimg_bgcolor}
            order={node.bq_coffee.txtimg_order}
            rowimg={node.bq_coffee.txtimg_img.publicURL}
          />

          <Products />

          <Outrolocation outro={node.bq_marker.location_description} />

          <OutroPiggy outro={node.bq_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query StrapiBoutique {
    allStrapiBoutique {
      edges {
        node {
          id # id of the node
          bq_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          bq_onecol {
            id
            onecol_title
            onecol_description
          }
          bq_relax {
            id
            txtimg_title
            txtimg_subtitle
            txtimg_order
            txtimg_description
            txtimg_img {
              publicURL
            }
          }
          bq_coffee {
            id
            txtimg_title
            txtimg_subtitle
            txtimg_description
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          bq_marker {
            id
            location_description
          }
          bq_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
